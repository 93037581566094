.checkout-container {
  width: 100%;
  padding: 15px 20px;
  .checkout-container-title {
    padding: 5px 0px;
    font-weight: bold;
  }
}

.no-data-container {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #7070705e;
}

@media (min-width: 768px) {
  .checkout-container {
    padding: 15px 0px;
  }
}