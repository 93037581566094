.result-page {
  background-color: #FFFFFF;
  padding: 20px 0px;
  animation: fadein 200ms linear;
}

iframe {
  border: none;
}

.numero-pedido {
  color: #00B8FA;
}

.messenger-div-container {
  display: flex;
  justify-content: center;
}

.download-a {
  display: flex;
  justify-content: center;
  a {
    width: 100%;
    background-color: #727272;
    text-align: center;
    padding: 10px;
    color: #FFFFFF;
    &:active {
      box-shadow: 0px 0px 5px #727272cc;
      color: #00B8FA;
    }
  }
}

.paragrafo-seta-container {
  padding-top: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  .paragrafo-seta {
    color: #D40013;
    text-align: center;
  }
}

.messenger-button-container {
  margin-top: 68px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.arrows-container {
  display: flex;
  justify-content: center;
}

.arrow_box {
	position: relative;
	background: #d53a22;
  border: 1px solid #d53a22;
  height: 70px;
  padding: 15px 0px 0px 20px;
  color: #FFFFFF;
  border-radius: 10px 0px 0px 10px;
  p {
    color: #FFFFFF;
  }
}
.arrow_box:after, .arrow_box:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(213, 58, 34, 0);
	border-left-color: #d53a22;
	border-width: 70px;
	margin-top: -70px;
}
.arrow_box:before {
	border-color: rgba(213, 58, 34, 0);
	border-left-color: #d53a22;
	border-width: 70px;
	margin-top: -70px;
}

.container-seta-vermelha {
  transition: margin 500ms linear;
  margin: 50px 0px;
  max-width: 200px;
  width: 100%;
}

.demais-parcelas-p {
  font-size: 14px;
}

.image-messenger-onsurance {
  width: 100%;
  img {
    width: 100%;
    max-width: 400px;
  }
}

.obs-small {
  font-size: 12px;
}

.h3-alternativo {
  padding: 20px 0px;
  margin: 0px;
  &::before {
    margin: 0px 0px 10px 0px;
  }
}

.only-768px {
  display: none;
}

@media (min-width: 768px) {
  .result-page {
    max-width: 900px;
    padding: 20px 40px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
  }
  .download-messeger-container {
    padding-top: 20px;
  }
  .image-messenger-onsurance {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      min-width: 150px;
      max-width: 300px;
    }
  }
  .only-768px {
    display: block;
  }
}

@media (max-width: 575px) {
  .container-seta-vermelha {
    margin: 20px 0px 50px 0px;
  }
  .messenger-button-container {
    margin-top: 0px;
  }
  .arrow_box {
    position: relative;
    background: #d53a22;
    border: 1px solid #d53a22;
    border-radius: 5px;
  }
  .arrow_box:after, .arrow_box:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .arrow_box:after {
    border-color: rgba(213, 58, 34, 0);
    border-top-color: #d53a22;
    border-width: 25px;
    margin-left: -25px;
    margin-top: 0px;
  }
  .arrow_box:before {
    border-color: rgba(213, 58, 34, 0);
    border-top-color: #d53a22;
    border-width: 25px;
    margin-left: -25px;
    margin-top: 0px;
  }
}

@media (max-width: 375px) {
  .table-boleto {
    font-size: 12px;
  }
}