body, html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8fc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2, h1, h3, h4 {
  font-family: 'Montserrat', sans-serif;
  color: #707070;
  padding: 0px 20px;
  &::before {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background-color: #00B8FA;
    margin: 0px auto 10px auto;
  }
}

p, label {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #707070;
  margin: 0px;
  line-height: 1.4;
  text-align: left;
}

button {
  font-size: 18px;
}

small {
  font-size: 14px;
  color: #707070;
  font-family: 'Open Sans', sans-serif;
}