.assistencia-image-container {
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
    max-width: 100px;
  }
}

.assistencia-col-1 {
  display: flex;
  align-items: center;
  height: 100%;
  p {
    padding: 5px 0px;
  }
}

.selected {
  transition: all 500ms linear;
  background-color: rgba(135, 206, 250, 0.2);
  &:hover {
    background-color: rgba(135, 206, 250, 0.3);
  }
}

.assistencia-col-2 {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  p {
    padding: 5px 0px;
  }
}

.assistencia-remove-button {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  border: solid #F0F0F0;
  border-width: 1px 0px 0px 0px;
  color: #e57373;
}

.assistencia-select-button {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  border: solid #F0F0F0;
  border-width: 1px 0px 0px 0px;
  color: #81c784;
}