.primary-button {
  background-color: #00B8FA;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  color: #FFFFFF;
  padding: 10px 20px;
  transition: all 200ms linear;
  cursor: pointer;
  -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
  &:active {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    background-color: #48CFFF;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #71d9ff;
    color: #FFFFFF;
    border: 1px solid #71d9ff;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: #00B8FA;
  }
}

.confirm-button {
  background-color: #FFFFFF;
  border: 1px solid #00B8FA;
  border-radius: 5px;
  color: #00B8FA;
  padding: 10px 20px;
  transition: all 200ms linear;
  cursor: pointer;
  -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
  &:active {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    background-color: #00B8FA;
    color: #FFFFFF;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #00B8FA;
    color: #FFFFFF;
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: #FFFFFF;
    color: #00B8FA;
  }
}

.default-button {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  color: #707070;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 200ms linear;
  -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
  &:active {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    background-color: #707070;
    color: #FFFFFF;
    border: 1px solid rgba(112, 112, 112, 1);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    border: 1px solid rgba(112, 112, 112, 1);
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    background-color: #FFFFFF;
  }
}

@media (min-width: 768px) {
  .default-button {
    border: 1px solid rgba(112, 112, 112, 1);

    &:hover {
      background-color: rgba(112, 112, 112, 1);
      color: #FFFFFF;
    }
  }
}

.primary-button.true {
  width: 100%;
  padding: 5px;
}

.confirm-button.true {
  width: 100%;
  padding: 5px;
}

.default-button.true {
  width: 100%;
  padding: 5px;
}