.checkout-iten-container {
  margin: 10px 0px;
  border: solid 1px #649ff761;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 0px 2px #649ff796;
  &.active {
    border-color: #FF8484;
    box-shadow: 0px 0px 2px #FF84845e;
  }
  &.recomenda {
    border: solid 1px #0000001a;
    box-shadow: 0px 0px 2px #0000001a;
  }
}

.checkout-iten-value {
  font-size: 15.5px;
  text-align: left;
  padding: 0px 20px;
}

.position-bottom {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.checkout-iten-title {
  font-size: 15.5px;
  text-align: left;
  padding: 0px 20px;
}

.checkout-iten-image-container {
  min-width: 103px;
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid #649ff75e;
  border-radius: 5px;
  background-color: #649ff72c;
  &.active {
    border: 1px solid #f55c5c5e;
    background-color: #f55c5c26;
  }
  &.recomenda {
    border: 1px solid #CCCCCC5e;
    background-color: #CCCCCC4D;
  }
}

.checkout-iten-image {
  max-width: 130px;
  width: 100%;
  padding: 10px;
}

.checkout-iten-text-container {
  height: 100%;
}

.checkout-iten-button-container {
  position: relative;
  width: 100%;
  z-index: 1;
  .checkout-iten-remove-button {
    position: absolute;
    right: 0;
    margin-right: 20px;
    border: none;
    color: #F55C5C;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    &:hover {
      color: #f55c5ce0;
    }
  }
  .checkout-iten-add-button {
    position: absolute;
    right: 0;
    font-size: 14px;
    margin-right: 20px;
    border: none;
    color: #649ff7;
    outline: none;
    cursor: pointer;
    background-color: #FFFFFF;
    &:hover {
      color: #649ff7e0;
    }
  }
}

@media (min-width: 768px) {
  .checkout-iten-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: unset;
    img {
      max-width: 140px;
    }
  }
  .checkout-iten-value {
    font-size: 18px;
    text-align: left;
  }
  .checkout-iten-title {
    font-size: 18px;
    text-align: left;
  }
}

@media (max-width: 360px) {
  .checkout-iten-title {
    font-size: 12px;
  }
  .checkout-iten-value {
    font-size: 12px;
  }
}