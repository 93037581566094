.recibo-container {
  max-width: 400px;
  min-width: 200px;
}

.numero-pedido {
  color: #707070 !important;
  text-align: center;
  font-weight: bold;
  padding: 0px;
  &::before {
    display: none;
  }
}

.receipt-itens {
  margin-bottom: 0.5em;
}

.receipt-div {
  padding: 20px 20px 0px 20px;
}

.receipt-divider {
  border-top: 2px dashed #707070;
  width: 100%;
  display: block;
  margin: 20px 0px;
}

.triangulo-cima {
  width: 0; 
  height: 0; 
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  
  border-top: 20px solid blue;
}