.valor-de-informacao-parcelas {
  text-align: end;
}

.select-qtd-parcelas {
  width: 100%;
}

@media screen and (max-width: 425px) {
  .select-qtd-parcelas {
    width: 89vw;
  }
}