.billing-data-select-container {
  height: 100%;
  padding: 15px 0px;
  .select-estados-combobox {
    width: 100%;
    border: 1px solid #707070;
    border-radius: 5px;
    &:focus {
      border-color: #00B8FA;
    }
    div {
      border: none !important;
    }
  }
}