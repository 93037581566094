.checkout-page {
  background-color: #FFFFFF;
  padding: 20px 0px 0px 0px;
  animation: fadein 200ms linear;
}

.add-itens-container {
  padding: 0px 20px 20px 20px;
}

.itens-recomendados-container {
  padding: 0px 20px 20px 20px;
}

.checkout-valor-total {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #CCCCCC69;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 26px;
    color: #707070;
    text-align: center;
  }
  .checkout-valor-total-information{
    font-size: 14px;
  }
}

@media (min-width: 768px){
  .checkout-page {
    max-width: 900px;
    padding: 20px 40px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
  }
  .add-itens-container {
    padding: 0px 0px 20px 0px;
    .add-itens-title {
      font-weight: bold;
    }
  }
  .itens-recomendados-container {
    padding: 0px 0px 20px 0px;
  }
}