.indicante-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: solid 1px #000000;
    border-radius: 10px;
    padding: 10px;
    width: 250px;
    height: 90px;
    margin: 10px;
    &.active {
        border-color: #1890FF;
    }
}

.indicante-item-description {
    height: 100%;
}

.indicante-item-container:hover {
    box-shadow: 0px 0px 5px #000000;
    cursor: pointer;
    &.active {
        box-shadow: 0px 0px 2px #0f579b;
    }
}

.indicante-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}