.onsurance-input {
  margin: 10px 0px;
  width: 100%;
  input[type=text] {
    width: 100%;
    border: 1px solid #707070;
    padding: 5px 10px;
    border-radius: 5px;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    transition: border-color 200ms linear;
    &:focus {
      border-color: #00B8FA;
      box-shadow: 0px 0px 1px #00B8FA;
      -moz-box-shadow: 0px 0px 1px #00B8FA;
      -webkit-box-shadow: 0px 0px 1px #00B8FA;
      outline: none;
    }
  }
}

.required-input {
  color: #FF8484;
  font-size: 16px;
}