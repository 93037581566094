.billing-data-page {
  background-color: #FFFFFF;
  padding: 30px 0px 0px 0px;
  animation: fadein 200ms linear;
}

.billing-data-valor-total {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.billing-data-valor-frete {
  font-size: 18px;
  margin-top: 5px;
  &.rigth {
    text-align: right;
  }
}

@media (min-width: 768px){
  .billing-data-page {
    background-color: #FFFFFF;
    padding: 20px 40px;
    max-width: 900px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
  }
}