.cotacao-option-container {
  cursor: pointer;
  margin: 20px 0px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  transition: all 200ms;
  &:active {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
  }
  .cotacao-option-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 15px;
    p {
      color: #FFFFFF;
      font-size: 22px;
    }
  }
  .cotacao-option-text {
    padding: 10px 0px;
    p {
      line-height: 1.3;
      color: #FFFFFF;
    }
  }
}

.cotacao-option-container.azul {
  background-color: #00B8FA;
  &:hover, &:active {
    background-color: #00B8FACC;
  }
}

.cotacao-option-container.cinza {
  background-color: #808080;
  &:hover, &:active {
    background-color: #808080CC;
  }
}

.cotacao-option-container.verde {
  background-color: #6895A0;
  &:hover, &:active {
    background-color: #6895A0CC;
  }
}

.cotacao-option-container.azul-escuro {
  background-color: #6882A0;
  &:hover, &:active {
    background-color: #6882A0CC;
  }
}

@media (min-width: 768px){
  .cotacao-option-container {
    height: 100%;
    max-height: 209px;
  }
}