.indications-container {
    width: 100%;
    padding: 15px 20px;
    .indications-container-title {
        padding: 5px 0px;
        font-weight: bold;
        margin-bottom: 20px;
    }
}

.indications-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

@media (min-width: 768px) {
    .indications-container {
      padding: 15px 0px;
    }
}