.app {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.pl-1 {
  padding: 0px;
}

.pr-1 {
  padding: 0px;
}

.pl-2 {
  padding: 0px;
}

.pr-2 {
  padding: 0px;
}

.justify-around {
  justify-content: space-around;
}

.logo-container {
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 360px;
  }
}

.voltar-md {
  display: none;
}

.only-large {
  display: none;
}

@media (min-width: 768px) {
  .app {
    padding-bottom: 20px;
  }
  .voltar-md {
    display: block;
  }
  .voltar-xs {
    display: none;
  }
  .only-small {
    display: none;
  }
  .only-large {
    display: block;
  }
  .pl-1 {
    padding: 0px 0px 0px 10px;
  }
  .pr-1 {
    padding: 0px 10px 0px 0px;
  }
  .pl-2 {
    padding: 0px 0px 0px 20px;
  }
  .pr-2 {
    padding: 0px 20px 0px 0px;
  }
}