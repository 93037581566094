.valor-credito-p {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 10px 0px 10px 0px;
  width: 100%;
  .wallet-icon {
    color: #00B8FA;
  }
  .valor-creditos {
    font-size: 25px;
    padding-left: 6px;
  }
}

@media (min-width: 768px){
  .valor-credito-p {
    padding: 0px;
  }
}