.divider-container {
  width: 100%;
  p {
    text-align: center;
    line-height: 1;
    margin: 0px 20px;
    width: 85%;
  }
  .divider {
    height: 1px;
    width: 15%;
    background-color: rgba(112, 112, 112, 0.4);
    margin: auto 0px;
  }
}

@media (min-width: 768px){
  .divider.true {
    display: none;
  }
}
