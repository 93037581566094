.jumbotron {
  background-color: #F2F2F2;
  padding: 20px;
  text-align: justify;
  hyphens: auto;
}

@media (min-width: 768px) {
  .jumbotron{
    border-radius: 5px;
  }
}