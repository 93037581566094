.radio-button-container {
  width: 100%;
  input[type = radio] {
    display: none;
  }
  label {
    cursor: pointer;
    transition: all 400ms;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 1);
    padding: 15px 10px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input[type = radio]:checked~label {
    background-color: rgba(1,183,255,.1);
    color: #01B7FF;
    border-color: #01B7FF;
  }
  input[type = radio]:disabled~label {
    cursor: not-allowed !important;
    background-color: #F0F0F0;
  }
}