.recarga-credito-page {
  background-color: #FFFFFF;
  padding: 20px 0px 0px 0px;
  animation: fadein 200ms linear;
}

.title-without-content {
  &::before {
    display: none;
  }
}

.check-button-recarga {
  margin: 0px 0px 0px 10px;
  height: 100%;
  width: 30px;
  transition: color linear 200ms;
  color: #91d5ff;
  &:hover {
    color: #66b9ec;
    cursor: pointer;
  }
}

@media (min-width: 768px){
  .recarga-credito-page {
    max-width: 900px;
    padding: 20px 40px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
  }
}