.loading-section {
  display: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0px 0px 46px 2px rgba(0,0,0,0.48);
  -moz-box-shadow: inset 0px 0px 46px 2px rgba(0,0,0,0.48);
  box-shadow: inset 0px 0px 46px 2px rgba(0,0,0,0.48);
  z-index: 100;
  &.active {
    display: block;
  }
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.spin {
  color: #00B8FA;
  animation-name: spin;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}