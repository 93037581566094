.purchase-page {
  background-color: #FFFFFF;
  padding: 30px 0px 0px 0px;
}

.product-card {
  .image-item-cover {
    position: relative;
    .button-purchase-item {
      position: absolute;
      top: 90%;
      left: 0;
      opacity: 0;
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  &:hover {
    .button-purchase-item {
      opacity: 0.95;
    }
    img {
      box-shadow: inset 0px 0px 10px 30px rgba(0,0,0,0.5);
    }
  }
}

.faca-cotacao-section {
  margin: 30px 0px;
}

.section-ja-possuo-cotacao {
  padding: 0px 20px;
  margin: 30px 0px 10px 0px;
  animation: fadein 1s;
}

// section-buttons

.section-numero-cotacao {
  padding: 0px 20px !important;
  width: 100%;
  margin: 10px 0px;
  animation: fadein 1s;
}

.product-col {
  animation: fadein 1s;
}

.final-content {
  margin-bottom: 20px;
}

.display-error {
  color: #FF8484;
  opacity: 0;
  transition: opacity 300ms;
  &.active {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .purchase-page {
    max-width: 900px;
    padding: 20px 40px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
  }
  // .section-numero-cotacao {
  //   width: 60%;
  //   padding: 30px 60px !important;
  //   border-radius: 5px;
  //   box-shadow: 0px 0px 4px #70707086;
  //   margin: 20px 0px 25px 0px;
  //   background-color: rgba(187, 187, 187, 0.1);
  // }
  .section-buttons {
    margin: 10px 5px;
    padding: 0px;
  }
  .product-col {
    padding: 0px 10px !important;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}